import request from '@/utils/request'

export function login(username, password, googlekey) {
  return request({
    url: 'crm/auth/login',
    method: 'post',
    data: {
      username,
      password,
      googlekey
    }
  })
}

export function getInfo() {
  return request({
    url: 'crm/auth/info',
    method: 'get'
  })
}
