/**
 * Created by jiachenpan on 16/11/18.
 */
export function subsctringFileName(imageUrl) {
  if(imageUrl.includes('image')){
    return imageUrl.split('image/')[1];
  }else {
    return imageUrl.split('files/')[1];
  }
}

export function parseTime(time) {
  if (time) {
    try {
      var date = new Date(time);
    }catch (e) {
      date = new Date(time.replace(/-/g,"/"));
    }
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  } else {
    return ''
  }
}

export function parseDate(time) {
  if (time) {
    var date = new Date(time)
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接
    return year + '-' + month + '-' + day
  } else {
    return ''
  }
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 替换邮箱字符
export function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    const str = email.split('@')
    let _s = ''
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*'
      }
    }
    var new_email = str[0].substr(0, 3) + _s + '@' + str[1]
  }
  return new_email
}

// 替换手机字符
export function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7)
  }
  return new_mobile
}
export function getLoginPageSiteName() {
  let title = 'Maxain CRM';

  return title;
}

// 数组去重
export function uniquesArr(obj) {
  var uniques = [];
  var stringify = {};
  for (var i = 0; i < obj.length; i++) {
      var keys = Object.keys(obj[i]);
      keys.sort(function(a, b) {
          return (Number(a) - Number(b));
      });
      var str = '';
      for (var j = 0; j < keys.length; j++) {
          str += JSON.stringify(keys[j]);
          str += JSON.stringify(obj[i][keys[j]]);
      }
      if (!stringify.hasOwnProperty(str)) {
          uniques.push(obj[i]);
          stringify[str] = true;
      }
  }
  uniques = uniques;
  return uniques;
}


export function init_date() {
  //现在
  var time = new Date();
  var day = ('0' + time.getDate()).slice(-2);
  var month = ('0' + (time.getMonth() + 1)).slice(-2);
  var today = time.getFullYear() + '-' + month + '-' + day ;
  var firstday = time.getFullYear() + '-' + month + '-01';

  //1.一个月前 month直接减1
  var monthAgo = ('0' + (time.getMonth() )).slice(-2);
  var oneMonAgo = time.getFullYear() + '-' + monthAgo + '-' + day ;
  if(month=="01"){
    monthAgo = 12;
    var yearAgo = time.getFullYear()-1;
    oneMonAgo =yearAgo + '-' + monthAgo + '-' + day ;
  }
  //如果日期有效
  if(judgeDate(oneMonAgo)){
    return {
      today: today,
      firstday: firstday,
      oneMonAgo: oneMonAgo,
    }
  }else{
    //2.减去30天
    var ago=new Date(time.getTime() - 30*24*60*60*1000); //30天前
    var day = ('0' + ago.getDate()).slice(-2);
    var month = ('0' + (ago.getMonth() + 1)).slice(-2);
    var oneMonAgo = ago.getFullYear() + '-' + month + '-' + day ;
    return {
      today: today,
      firstday: firstday,
      oneMonAgo: oneMonAgo,
    }
  }
}

function judgeDate(date){
  const rmons = [31,29,31,30,31,30,31,31,30,31,30,31],
    pmons = [31,28,31,30,31,30,31,31,30,31,30,31];
  var year = parseInt(date.substr(0,4)),
    mon = parseInt(date.substr(5,7)),
    day = parseInt(date.substr(8,10));
  if(year % 4 == 0 && year % 100 != 0 || year % 400 == 0){
    return mon > 0 && mon <=12 && day > 0 && day <= rmons[mon-1];
  }else{
    return mon > 0 && mon <=12 && day > 0 && day <= pmons[mon-1];
  }
}

export function gePayTypeCommon(array){
  let tempArr = [];
  for (let i = 0; i < array.length ; i++) {
    tempArr.push(array[i][array[i].length - 1]);
  }
  return tempArr.join(',');
}

export function handleWithdrawInfo(vm){
  for (let i = 0; i < vm.useInfo.collections.length; i++) {
    if(vm.useInfo.collections[i].payTypeId == 4){
      if(vm.useInfo.collections[i].custPayDetail){
        vm.backInfo = vm.useInfo.collections[i].custPayDetail;
      }
    }
    if(vm.useInfo.collections[i].payTypeId == 5){
      vm.usdtInfo.usdtQrPic = vm.useInfo.collections[i].custPayDetail.qrPic;
      vm.usdtInfo.usdtAdress = vm.useInfo.collections[i].custPayDetail.address;
    }
    if(vm.useInfo.collections[i].payTypeId == 6){
      vm.otcInfo.otcQrPic = vm.useInfo.collections[i].custPayDetail.qrPic;
      vm.otcInfo.otcAdress = vm.useInfo.collections[i].custPayDetail.address;
    }
  }
}

export function calcDuringDate(params){
  let duringDays = (Date.parse(params.endTime) - Date.parse(params.startTime)) /1000 / 86400;
  return duringDays;
}

export function createUuid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + S4() +  S4() + S4() +  S4() + S4() + S4());
  // guid2() // "748eea29-f842-4af9-a552-e1e1aa3ed979"
}

/*将图片转为base64*/
export function getBase64(file){
  return new Promise(function(resolve, reject) {
    let reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function() {
      imgResult = reader.result;
    };
    reader.onerror = function(error) {
      reject(error);
    };
    reader.onloadend = function() {
      // 超过0.5压缩,
      const isLt5M = file.size / 1024 / 1024 > 0.5;
      if (isLt5M) {
        compress(reader.result,file.size).then((res) => {
          resolve(res);
        });
      } else {
        resolve(imgResult);
      }
    };
  });

}


export function compress(base64Img,fileSize){
  return new Promise(function(resolve, reject) {
    var img=new Image();
    img.src=base64Img;
    img.onload = () => {
      var width=img.width;
      var height=img.height;
      var canvas=document.createElement("canvas");
      canvas.width=width;
      canvas.height=height;
      canvas.getContext("2d").drawImage(img,0,0,width,height);

      let quality = 0.5;
      let size = fileSize / 1024 / 1024;
      if(0.5 < size && size < 1){
        quality = 0.3;
      }else if( 1 < size && size < 3){
        quality = 0.4;
      }else if( 3 < size && size < 10){
        quality = 0.5;
      }
      resolve(canvas.toDataURL('image/jpeg', quality));
    };
  });
}

// 将base64转为file
//   base64转file
export function base64toFile(dataBase64, filename = "file") {
  let arr = dataBase64.split(",");
  let mime = arr[0].match(/:(.*?);/)[1]; //设置file文件流的type名称
  let suffix = mime.split("/")[1]; //设置file文件流的name名称
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
}


export function limitImageSizeFormat(file,vm){
  console.log(file);
  const isLt10M = file.size / 1024 / 1024 < 10;
  console.log(isLt10M);
  if (!isLt10M) {
    vm.$message.error(vm.$t('上传图片大小不能超过10MB!'));
  }
  return isLt10M;
}

export function getLimitUploadImageFormat(){
  return 'image/jpg,image/jpeg,image/png,image/bmp,image/tiff';
}
