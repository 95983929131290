<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'payTypeOptions'
    ])
  },
  created() {
    if (this.payTypeOptions == null) {
      this.$store.dispatch("GetPayType");
    }
    if (this.outTypeOptions == null) {
      this.$store.dispatch("GetOutPayType");
    }

  },
}
</script>
