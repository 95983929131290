<template>
  <div
    v-if="channelDesc"
    class="pay-tips fs12 font333"
  >
    <p class="p-text" v-html="channelDesc"></p>
  </div>
</template>

<script>
export default {
  name: 'channelDesc',
  props: {
    channelDesc: {
      type: String,
      default: '',
    }
  }
};
</script>

<style lang="less" scoped>
.pay-tips {
  padding: 20px;
  background-color: rgba(216,216,216, .1);
  padding-top: 20px;
  .p-text {
    margin-bottom: 3px;
  }
}
</style>
