<template>
  <el-radio-group
      v-model="type"
      style="margin-bottom: 20px;"
      size="small"
  >
    <el-radio-button label="USD">美元账户</el-radio-button>
    <el-radio-button label="USC">美分账户</el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  name: 'CentTab',
  data() {
    return {
      type: 'USD',
    }
  },
  watch: {
    type(newValue) {
      this.$emit('send-type',newValue);
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.cent-tab {

}
</style>
