<template>
    <el-pagination
            style="margin-top: 8px;"
            v-if="totalPage >= 1"
            :total="totalPage"
            layout="total, prev, pager, next, sizes"
            :current-page.sync="pageSelf"
            @size-change="sizeChange"
            @current-change="pageChange"/>
</template>

<script>
  export default {
    name: 'publicPagination',
    props: {
      totalPage: {
        type: Number,
        default: 0
      },
      currentPage: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        pageSelf: this.page + 1,
      }
    },
    watch: {
      currentPage(newValue, oldValue) {
        this.pageSelf = newValue + 1;
      }
    },
    methods: {
      pageChange(e) {
        this.$emit('pageChange',e);
      },
      sizeChange(e) {
        this.$emit('sizeChange',e);
      },
    }
  }
</script>

<style scoped>

</style>
