import { login, getInfo } from '@/api/system/login'
import { getUserTree } from "@/api/system/permission";
import { parseTime } from '@/utils/index'
const user = {
  state: {
    token: localStorage.getItem('token'),
    userId:'',
    name: '',
    siteId: '',
    siteName: '',
    email: '',
    avatar: '',
    createTime: '',
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false,
    userDataPermission:[],
    messageOrMailRecordId: '', //站内信或者邮件id,
    auditSelectData: [],//
    merchantCustLvl: '', // 邀请链接跳转代理商管理列表
    cusmgrCustLvl: '', // 邀请链接跳转客户列表
    customerIntergralJumpToAuditListOfId: '', // 积分管理-客户积分跳转积分审核列表,
    depositAccount: '',// 入金
  },

  mutations: {
    setAuditSelectData: (state,auditSelectData) =>{
      state.auditSelectData = auditSelectData;
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_SITEID: (state, siteId) => {
      state.siteId = siteId
    },
    SET_SITENAME: (state, siteName) => {
      state.siteName = siteName;
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_DATA_PERMISSION: (state, userDataPermission) => {
      state.userDataPermission[0] = userDataPermission
    },
    SET_CREATE_TIME: (state, createTime) => {
      state.createTime = createTime
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus
    },
    setMessageOrMailRecordId(state,messageOrMailRecordId){
      state.messageOrMailRecordId = messageOrMailRecordId;
    },
    setMerchantCustLvl(state,custLvl){
      state.merchantCustLvl = custLvl;
    },
    setCusmgrCustLvl(state,cusmgrCustLvl){
      state.cusmgrCustLvl = cusmgrCustLvl;
    },
    setCustomerIntergralJumpToAuditListOfId(state,customerIntergralJumpToAuditListOfId){
      state.customerIntergralJumpToAuditListOfId = customerIntergralJumpToAuditListOfId;
    },
    setDepositAccount(state,depositAccount){
      state.depositAccount = depositAccount;
    },
  },

  actions: {

    GetLoginUserPermission({ commit }, name) {
      let params = { userName: name };
      getUserTree(params)
        .then(res => {
          // 处理权限数据
          commit('SET_USER_DATA_PERMISSION', res.content[0])
        })
        .catch(err => {});
    },
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username
      const password = userInfo.password
      const googlekey = userInfo.googlekey
      return new Promise((resolve, reject) => {
        login(username, password, googlekey).then(res => {
          localStorage.setItem('token',res.token);
          commit('SET_TOKEN', res.token)
          setUserInfo(res.user, commit)
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit('SET_LOAD_MENUS', true);
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          setUserInfo(res, commit)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');

        commit('SET_ROLES', [])
        commit('SET_USER_DATA_PERMISSION', [])
        localStorage.clear();
        resolve()
      })
    },

    updateLoadMenus({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS', false)
      })
    }
  }
}

export const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT'])
  } else {
    commit('SET_ROLES', res.roles)
  }
  commit('SET_USERID', res.id)
  commit('SET_SITENAME', res.srSite && res.srSite.siteName)
  commit('SET_NAME', res.username)
  commit('SET_SITEID', res.siteId)
  commit('SET_AVATAR', res.avatar)
  commit('SET_EMAIL', res.email)
  commit('SET_CREATE_TIME', parseTime(res.createTime))
}

export default user
