<template>
<p class="upload-img-tips">格式仅限jpg/jpeg/png/bmp/tiff，大小≤10M</p>
</template>

<script>
export default {
  name: 'uploadImgTips'
};
</script>

<style lang="less" scoped>
.upload-img-tips {
  color: #999999;
  line-height: 1.5!important;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
</style>
