const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userDataPermission: state => state.user.userDataPermission,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  userId: state => state.user.userId,
  name: state => state.user.name,
  siteId: state => state.user.siteId,
  siteName: state => state.user.siteName,
  createTime: state => state.user.createTime,
  email: state => state.user.email,
  roles: state => state.user.roles,
  loadMenus: state => state.user.loadMenus,
  messageOrMailRecordId: state => state.user.messageOrMailRecordId,

  permission_routers: state => state.permission.routers,
  dataPermission: state => state.permission.dataPermission,
  addRouters: state => state.permission.addRouters,
  filesUploadApi: state => state.api.filesUploadApi,
  picturesUploadApi: state => state.api.picturesUploadApi,
  language: state => state.app.language,


  // 代理相关开始
  ibTypeOptions: state => state.ib.ibTypeOptions,
  ibStatusOptions: state => state.ib.ibStatusOptions,
  commiTypeOptions: state => state.ib.commiTypeOptions,
  currencyTypeOptions: state => state.ib.currencyTypeOptions,
  commiStatusOptions: state => state.ib.commiStatusOptions,
  sourceOptions: state => state.ib.sourceOptions,
  busSecStatusOptions: state => state.ib.busSecStatusOptions,
  levelOptions: state => state.ib.levelOptions,
  currencyPairOptions: state => state.ib.currencyPairOptions,
  computeTypeOptions: state => state.ib.computeTypeOptions,
  isDefaultOptions: state => state.ib.isDefaultOptions,
  commiBaseOptions: state => state.ib.commiBaseOptions,
  ibRankOptions: state => state.ib.ibRankOptions,
  rewardTypeOptions: state => state.ib.rewardTypeOptions,
  commTypeOptions: state => state.ib.commTypeOptions,
  directCommTypeOptions: state => state.ib.directCommTypeOptions,
  indirectCommTypeOptions: state => state.ib.indirectCommTypeOptions,
  isValidOptions: state => state.ib.isValidOptions,
  groupOptions: state => state.ib.groupOptions,
  mtGroups: state => state.ib.mtGroups,
  categorys: state => state.ib.categorys,
  bussSectors: state => state.ib.bussSectors,
  cusStatusOptions: state => state.ib.cusStatusOptions,
  cusAuditStatusOptions: state => state.ib.cusAuditStatusOptions,
  custSourceOptions: state => state.ib.custSourceOptions,
  withdrawStatusOptions: state => state.ib.withdrawStatusOptions,
  depositStatusOptions: state => state.ib.depositStatusOptions,
  payTypeOptions: state => state.ib.payTypeOptions,
  tradeCmdOptions: state => state.ib.tradeCmdOptions,
  outTypeOptions: state => state.ib.outTypeOptions,
  payStatusOptions: state => state.ib.payStatusOptions,
  // 代理相关结束
  // 上传图片的路径
  uploadImgUrl: state => state.api.filesUploadApi,

  // 审核多选
  auditSelectData: state => state.user.auditSelectData,

  // 入金
  depositAccount: state => state.user.depositAccount,

  merchantCustLvl: state => state.user.merchantCustLvl,
  cusmgrCustLvl: state => state.user.cusmgrCustLvl,
  customerIntergralJumpToAuditListOfId: state => state.user.customerIntergralJumpToAuditListOfId,
  isRefresh: state => state.app.isRefresh,
}
export default getters
