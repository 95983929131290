import { initData} from '@/api/data'

const ib = {
  state: {
    ibTypeOptions: [
        { key: 1, display_name: '个人' },
        { key: 2, display_name: '企业' },
        { key: 3, display_name: '直客' }
      ],
    commiTypeOptions: [
        { key: 7, display_name: '出佣到银行卡' },
        { key: 0, display_name: 'IB转账' },
        { key: 8, display_name: '出佣到USDT' },
        { key: 9, display_name: '出佣到OTC365' },
      ],
     currencyTypeOptions: [
        { key: 2, display_name: '人民币' },
        { key: 1, display_name: '美元' }
      ],
     busSecStatusOptions: [
        { key: 2, display_name: '无效' },
        { key: 1, display_name: '有效' }
      ],
     commiStatusOptions:[
        { key: 1, display_name: '待客服部审核' },
        { key: 2, display_name: '待清算部审核' },
        { key: 3, display_name: '待交易部审核' },
        { key: 4, display_name: '待清算部复核' },
        { key: 5, display_name: '待财务部出佣' },
        { key: 6, display_name: '已出佣' },
        { key: 7, display_name: '已否绝/取消' }
      ],
     ibStatusOptions:[
        { key: 1, display_name: '待客服部审核' },
        { key: 2, display_name: '待交易部审核' },
        { key: 3, display_name: '审核拒绝' },
        { key: 4, display_name: '审核通过' },
        { key: 5, display_name: '冻结' },
        { key: 6, display_name: '销户' }
      ],
    sourceOptions:[
        { key: 1, display_name: 'EMPLOYEE' },
        { key: 2, display_name: 'WEBSITE' },
        { key: 3, display_name: 'BAIDU/GOOGLE' },
        { key: 4, display_name: 'Wechat/Facebook' },
        { key: 5, display_name: 'Linkedin' },
        { key: 6, display_name: 'Other' }
      ],
    levelOptions: [
      { key: 'ALL', display_name: '全部级别' },
      { key: '1', display_name: 'Lev.A' },
      { key: '2', display_name: 'Lev.B' },
      { key: '3', display_name: 'Lev.C' },
      { key: '4', display_name: 'Lev.D' },
      { key: '5', display_name: 'Lev.E' },
      { key: '6', display_name: 'Lev.F' },
      { key: '7', display_name: 'Lev.G' },
      { key: '8', display_name: 'Lev.H' },
      { key: '99', display_name: 'Special'}
    ],
    currencyPairOptions: [
      { key: 'ALL', display_name: '全部' },
      { key: 'AUD/CAD', display_name: 'AUD/CAD' },
      { key: 'AUD/CHF', display_name: 'AUD/CHF' },
      { key: 'AUD/USD', display_name: 'AUD/USD' },
      { key: 'GBP/USD', display_name: 'GBP/USD' }
    ],
    computeTypeOptions: [
      { key: 1, display_name: '百分比' },
      { key: 2, display_name: '固定值' }
    ],
    isDefaultOptions: [
      { key: 1, display_name: '是' },
      { key: 2, display_name: '否' }
    ],
    commiBaseOptions: [
      { key: 1, display_name: '点值' },
      { key: 2, display_name: '点差' }
    ],
    ibRankOptions: [
      { key: 1, display_name: '主代理' },
      { key: 2, display_name: '子代理' }
    ],
    rewardTypeOptions: [
      { key: 1, display_name: '代理奖励' },
      { key: 2, display_name: '直客奖励' },
      // { key: 3, display_name: '员工奖励' }
    ],
    commTypeOptions: [
      { key: 1, display_name: '内佣' },
      // { key: 2, display_name: '外佣' },
      // { key: 3, display_name: '平级推荐' }
    ],
    directCommTypeOptions: [
      { key: 1, display_name: '$,每手/金额' },
      // { key: 2, display_name: '%,交易量百分比' },
      { key: 3, display_name: 'pip,点值/每手/金额' }
    ],
    indirectCommTypeOptions: [
      { key: 1, display_name: '-,上级标准-下级标准' },
      // { key: 2, display_name: '$,每手/金额' },
      // { key: 3, display_name: '%,下级返佣总额百分比' },
      // { key: 4, display_name: 'pip,点值/每手/金额' }
    ],
    isValidOptions: [
      { key: 1, display_name: '启用' },
      { key: 2, display_name: '禁用' }
    ],
    groupOptions: [
      { key: 1, display_name: 'A book' },
      { key: 2, display_name: 'B book' },
      { key: 3, display_name: '其他' }
    ],
    cusStatusOptions: [
      { key: 10, display_name: '注册未激活' },
      { key: 1, display_name: '注册未申请' },
      { key: 2, display_name: '申请未开户' },
      { key: 3, display_name: '开户未入金' },
      { key: 4, display_name: '入金未交易' },
      { key: 5, display_name: '已交易' },
      { key: 6, display_name: '销户' }
    ],
    cusAuditStatusOptions: [
      { key: 1, display_name: '客服审核' },
      { key: 2, display_name: '交易部审核' },
      { key: 3, display_name: '审核拒绝' },
      { key: 4, display_name: '审核通过' },
      { key: 5, display_name: '冻结' },
      { key: 6, display_name: '销户' }
    ],
    custSourceOptions: [
      { key: 1, display_name: '代理客户' },
      { key: 2, display_name: '直客' },
      { key: 3, display_name: '直客推荐' },
      { key: 4, display_name: '平级推荐' },
      { key: 5, display_name: '客户经理推荐' },
      { key: 6, display_name: '合作伙伴引流' }
    ],
    withdrawStatusOptions:[
      { key: 1, display_name: '客服部审核' },
      { key: 2, display_name: '清算部审核' },
      { key: 3, display_name: '交易部审核' },
      { key: 4, display_name: '清算部复核' },
      { key: 5, display_name: '财务部审核' },
      { key: 6, display_name: '审核通过' },
      { key: 99, display_name: '审核拒绝' }
    ],
    depositStatusOptions:[
      { key: 1, display_name: '财务部审核' },
      { key: 2, display_name: '客服部审核' },
      { key: 3, display_name: '清算部审核' },
      { key: 4, display_name: '交易部审核' },
      { key: 5, display_name: '清算部复核' },
      { key: 6, display_name: '审核通过' },
      { key: 7, display_name: '审核中' },
      { key: 99, display_name: '审核拒绝' }
    ],
    payStatusOptions:[
      { key: 1, display_name: '待支付' },
      { key: 2, display_name: '支付中' },
      { key: 3, display_name: '支付成功' },
      { key: 4, display_name: '支付失败' }
    ],
    payTypeOptions:null,
    outTypeOptions:null,
    tradeCmdOptions:[
      { key: 0, display_name: '买单' },
      { key: 1, display_name: '卖单' },
      { key: 2, display_name: '买入限价' },
      { key: 3, display_name: '卖出限价' },
      { key: 4, display_name: '买入止损订' },
      { key: 5, display_name: '卖出止损订' },
      { key: 99, display_name: '其他' }
    ],
    mtGroups:null,
    categorys:null,
    bussSectors:null

  },

  mutations: {
    SET_PayType: (state, payTypeOptions) => {
      state.payTypeOptions = payTypeOptions
    },
    SET_OutType: (state, outTypeOptions) => {
      state.outTypeOptions = outTypeOptions
    },
    SET_MtGroups: (state, mtGroups) => {
      state.mtGroups = mtGroups
    },
    SET_Categorys: (state, categorys) => {
      state.categorys = categorys
    },
    SET_BussSectors: (state, bussSectors) => {
      state.bussSectors = bussSectors
    }
  },

  actions: {
    // 获取分组信息
    GetMtGroups({ commit }) {
      return new Promise((resolve, reject) => {
        const url='/crm/tMt4Group'
        let parmas = {
          isMock:2
        }
        initData(url, parmas).then(res => {
          commit('SET_MtGroups', res.content)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
        })
      },
    // 获取交易品种信息
    GetCategorys({ commit }) {
      return new Promise((resolve, reject) => {
        const url='crm/tMt4TradeCategory'
          initData(url, '').then(res => {
            commit('SET_Categorys', res.content)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      },
      // 获取板块信息
    GetBussSectors({ commit }) {
      return new Promise((resolve, reject) => {
        const url='crm/tBusinessSector'
          initData(url, '').then(res => {
            commit('SET_BussSectors', res.content)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      },
      // 获取支付方式信息
    GetPayType({ commit }) {
      return new Promise((resolve, reject) => {
        const url='crm/dict'
        let parmas = {
          type:'depositPayTypeList',
          language:'zh_CN'
        }
          initData(url, parmas).then(res => {
            commit('SET_PayType', res.content)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      },
      // 获取出金支付方式信息
    GetOutPayType({ commit }) {
      return new Promise((resolve, reject) => {
        const url='crm/dict'
        let parmas = {
          type:'withdrawPayTypeList',
          language:'zh_CN'
        }
          initData(url, parmas).then(res => {
            commit('SET_OutType', res.content)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      }
  }
}

 export default ib
