import request from '@/utils/request'

export function initData(url, params) {
  return request({
    url: url,
    method: 'get',
    params
  })
}

export function getDictListByType(data) {
  return request({
    url: '/crm/fp/getDictListByType',
    method: 'get',
    params: data,
  });
}


export function UploadImg64(data) {
  return request({
    url: '/crm/file/uploadBase64Picture',
    method: 'POST',
    data,
  });
}

export function uploadFile(file){
  console.log(file.size);
  console.log(file.size / (1024 * 1024));
  var promise = new Promise((resolve,reject) => {
    let formData = new FormData();
    formData.append('file', file);
    let str = '';
    if(!process.env.VUE_APP_API){
      str = location.origin + '/crm/file/upload'
    }else {
      str = process.env.VUE_APP_API + 'crm/file/upload';
    }
    if(location.origin.indexOf('https') > -1 && process.env.VUE_APP_API.indexOf('https')==-1){
      str = str.replace('http','https');
    }

    fetch(str, {
      method: 'post',
      body: formData,
    }).then((res) => {
      return res.json();
    }).then((resbonse) => {
      resolve(resbonse);
    })
  })
  return promise;
};
